import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { createUseStyles } from 'react-jss'
import MapAndStreetViewSection from '../components/molecules/MapAndStreetViewSection'
import SectionTitle from '../components/atoms/SectionTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope, faTree } from '@fortawesome/free-solid-svg-icons'
import HighStandardsSection from '../components/molecules/HighStandardsSection'
import CustomGallery from '../components/molecules/Gallery'
import SurroundingsForGreenSchoolSection from '../components/molecules/SurroundingsForGreenSchoolSection'
import HeadSection from '../components/atoms/HeadSection'

const GreenSchoolPage = (props) => {
  const classes = useStyles()
  const { translations, path } = props
  return (
  <Layout path={path}>
    <Seo title={translations.green_school} description={translations.page_description}/>
    <div className={classes.pageContainer}>
      <HeadSection color='#94d224' content= {<p>{translations.sentence_one} {translations.sentence_two} </p> }/>
      <div className={classes.bookingContainer}>
        <div className={classes.bookingText}>
          <SectionTitle title={translations.title} subtitle={translations.subtitle} color='#94d224' backgroundColor='#94d224' />
        </div>
        <div className={classes.bookingBox}>
          <div style={{justifyContent: 'left',}} className={classes.bookingThreeBoxes}>
            <div className={classes.halfBox}>
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ backgroundColor: '#94d224',}} />
            </div>
            <div className={classes.halfBoxText}>
              <p style={{color: '#6B7671'}}>{translations.contact.address}</p>
              <span>{translations.contact.street}</span><br/>
              <span>{translations.contact.city}</span>
            </div>
          </div>
          <div style={{justifyContent: 'left',}} className={classes.bookingThreeBoxes}>
            <div className={classes.halfBox}>
              <FontAwesomeIcon icon={faPhone} style={{ backgroundColor: '#94d224', }} />
            </div>
            <div className={classes.halfBoxText}>
              <p style={{color: '#6B7671'}}>{translations.contact.call_us}</p>
              <span>{translations.contact.phone}</span><br/>
              <span>{translations.contact.mobile_phone}</span>
            </div>
          </div>
          <div style={{justifyContent: 'center',}} className={classes.bookingThreeBoxes}>
            <div className={classes.halfBox}>
              <FontAwesomeIcon icon={faEnvelope} style={{ backgroundColor: '#94d224', }} />
            </div>
            <div className={classes.halfBoxText}>
              <p style={{color: '#6B7671'}}>{translations.contact.email}</p>
              <p>{translations.contact.email_address}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.offerContainer}>
        <div className={classes.offerBox}>
          <div className={classes.offerBoxText}>
            <div className={classes.title}>
              {translations.green_house_offer}
            </div>
            <div className={classes.EmptySpace}>
              <span style={{display: 'block', width: '30px', margin: '15px 0 0 0', height: '2px', backgroundColor: '#94d224',}}></span>
            </div>
            <div className={classes.text}>
              <p>{translations.text.sentence_one}
              {translations.text.sentence_two}</p>
              <p>{translations.text.sentence_three}
              {translations.text.sentence_four}
              {translations.text.sentence_five}</p>
            </div>
          </div>
        </div>
        <div className={classes.offerBox}>
          <div className={classes.rightCol}>
            <CustomGallery page='green-school'/>
          </div>
        </div>

      </div>

      <HighStandardsSection color='#94d224' backgroundColor='#8abf27cc' borderColor='#8abf27cc'/>
      <SurroundingsForGreenSchoolSection color='#94d224' backgroundColor='#94d224'/>

    </div>
    <MapAndStreetViewSection/>

  </Layout>
  )
}

const useStyles = createUseStyles({
  pageContainer: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '1em',
  },
  box: {
    position: 'flex',
    flexWrap: 'wrap',
    marginTop: '30px',
    '&::before': {
      display: 'block',
      content: "''",
      width: '40px',
      height: '40px',
      position: 'stiky',
      borderTop: '3px solid #94d224',
      borderLeft: '3px solid #94d224',
    },
    '&::after': {
      display: 'block',
      content: "''",
      position: 'absolute',
      width: '40px',
      height: '40px',
      right: '20px',
      borderBottom: '3px solid #94d224',
      borderRight: '3px solid #94d224',
    },
  },
  p: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '22px',
    lineHeight: '130%',
    marginRight: '10px',
    paddingRight: '10px',
    fontWeight: 'normal',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#6b7671',
  },
  bookingContainer: {
    justifyContent: 'center',
    paddingLeft: '30px',
  },
  bookingText: {
    marginBottom: '30px',
  },
  bookingBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  bookingThreeBoxes: {
    display: 'flex',
    minWidth: '300px',
    width: '33.3%',
    // justifyContent: 'center',
    '& svg': {
    marginLeft: '2px',
    marginRight: '12px',
    marginTop: '10px',
    height: '5em',
    width: '5em !important',
    color: 'white',
    padding: '1.5em',
    position: 'absolute',
    },
  },
  halfBoxText: {
    maxWidth: '220px',
    display: 'block',
    marginLeft: '30%',
  },
  halfBox: {
    marginRight: '5px',
    paddingLeft: '5px',
  },
  offerContainer: {
    marginTop: '15px',
    padding: '1em 0 0 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  rightCol: {
    width: '100%',
    display: 'flex',
  },
  offerBox: {
    minWidth: '500px',
    display: 'flex',
    width: '45%',
    justifyContent: 'center',
    margin: '20px 20px 10px 20px',
  },
  offerBoxText: {
    display: 'block',
  },
  title: {
    color: '#94d224',
    fontSize: '20px',
    lineHeight: '18px',
    fontWeight: 'bold',
  },
  text: {
    lineHeight: '130%',
    textAlign: 'justify',
    fontSize: '20px',
    marginBottom: '1px',
  },

  '@media(max-width: 960px)': {
    bookingThreeBoxes: {
      justifyContent: 'stretch',
    },
  },
  '@media(max-width: 650px)': {
    pageConainer: {
      minWidth: '650px',
    },
    bookingThreeBoxes: {
      justifyContent: ' stretch ',
    },
  },
  '@media(max-width: 480px)': {
    pageConainer: {
      marginLeft: '20px',
    },
    offerBox: {
      minWidth: '330px',
      marginLeft: '10px',
      marginBottom: '-10px',
    },
  },
  '@media(max-width: 320px)': {
    offerBox: {
      marginLeft: '10px',
      paddingLeft: '10px',
      minWidth: '280px',
      maxWidth: '320px',
    },
  },
})

GreenSchoolPage.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.green_school_page,
  }
}

export default connect(mapStateToProps)(GreenSchoolPage)
import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import SectionTitle from '../atoms/SectionTitle'
import { connect } from 'react-redux'
import GoogleMap from './GoogleMap'
import DefaultMapMarker from '../atoms/DefaultMapMarker'

const SurroundingsForGreenSchoolSection= props => {
  const { translations, backgroundColor } = props
  const classes = useStyles(backgroundColor)
  const geoCodes = [
    { lat: 50.0618971, lng: 19.9345672, },
    { lat: 49.9823327, lng: 20.0579787, },
    { lat: 49.9690762, lng: 20.4277796, },
    { lat: 49.5988404, lng: 20.1290178, },
    { lat: 50.2109769, lng: 19.8232057, }
  ]
  return (
    <div>
      <SectionTitle title={translations.title} subtitle={translations.subtitle} color={props.color} backgroundColor={props.backgroundColor} paddingTop= '30px'/>
        <div className={classes.standardSection}>
            <div className={classes.attractionsBox}>
                <div className={classes.attractionsTitle}>
                    {translations.location}
                </div>
                <div style={{width: '30px', margin: '15px 0 15px 0', height: '2px', backgroundColor: `${props.backgroundColor}`
,}}></div>
                <ul className={classes.attractionsUl}>
                  <li> {translations.cities.wieliczka} </li>
                  <li> {translations.cities.bochnia} </li>
                  <li> {translations.cities.ojcow} </li>
                  <li> {translations.cities.krakow} </li>
                  <li> {translations.cities.lubomierz} </li>
                </ul>
                <div className={classes.attractionsTitle}>
                    {translations.attractions}
                </div>
                <div style={{width: '30px', margin: '15px 0 15px 0', height: '2px', backgroundColor: `${props.backgroundColor}`}}></div>
                <ul className={classes.attractionsUl}>
                  <li> {translations.attractions_do.castle} </li>
                  <li> {translations.attractions_do.hiking} </li>
                  <li> {translations.attractions_do.cultural_events} </li>
                  <li> {translations.attractions_do.swimming_pool} </li>
                </ul>
            </div>
            <div className={classes.attractionsBox}>
            <GoogleMap
              mapCenter={{ lat: 49.881009, lng: 20.085285, }}
              zoom={9}
              customStyles={{ height: '80%', }}

            >
            {geoCodes.map(place => (
              <DefaultMapMarker
                key={place.lat}
                {...place}
              />
            ))}

            </GoogleMap>
          </div>
        </div>
    </div>
  )
}

SurroundingsForGreenSchoolSection.propTypes = {
  translations: PropTypes.object.isRequired,
}

const useStyles = createUseStyles({

  standardSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  attractionsBox: {
    minWidth: '500px',
    minHeight: '300px',
    width: '50%',
    paddingLeft: '3px',
  },
  attractionsTitle: {
    color: '#6b7671',
    fontSize: '20px',
    lineHeight: '20px',
    marginTop: '30px',
    marginLeft: '20px',
  },
  attractionsUl: {
    padding: '2px',
    margin: '0',
    lineHeight: '30px',
    marginLeft: '15px',
  },
  '@media(max-width: 480px)': {
    attractionsBox: {
      minWidth: '310px',
    },
  },
  '@media(max-width: 320px)': {
    attractionsBox: {
      minWidth: '280px',
    },
    standardSection: {
      paddingLeft: '10px',
    },
  },
})

const mapStateToProps = state => {
  return {
    translations: state.translations.translations.surroundings_green_school,
  }
}

export default connect(mapStateToProps)(SurroundingsForGreenSchoolSection)
